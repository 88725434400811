

export default {
  name: 'HomePageAnimatedText',
  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  data () {
    return {
      typedText: '',
      textArray: this.api.data.items.map(({ text }) => text.trim()),
      typingDelay: 200,
      erasingDelay: 100,
      newTextDelay: 2000,
      textArrayIndex: 0,
      charIndex: 0,
      timeoutId: null,
    }
  },

  beforeDestroy () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  },

  mounted () {
    if (this.textArray.length) {
      this.timeoutId = setTimeout(this.type, this.newTextDelay + 250)
    }
  },

  methods: {
    type () {
      if (this.charIndex < this.textArray[this.textArrayIndex].length) {
        this.typedText += this.textArray[this.textArrayIndex].charAt(this.charIndex)
        this.charIndex++
        this.timeoutId = setTimeout(this.type, this.typingDelay)
      } else {
        this.timeoutId = setTimeout(this.erase, this.newTextDelay)
      }
    },

    erase () {
      if (this.charIndex > 0) {
        this.typedText = this.textArray[this.textArrayIndex].substring(0, this.charIndex - 1)
        this.charIndex--
        this.timeoutId = setTimeout(this.erase, this.erasingDelay)
      } else {
        this.textArrayIndex++
        if (this.textArrayIndex >= this.textArray.length) {
          this.textArrayIndex = 0
        }
        this.timeoutId = setTimeout(this.type, this.typingDelay + 1100)
      }
    },
  },
}
